import React from 'react';
import InfoCard from '../../components/corporative/InfoCard';

const BubbleMadnessPrivacyPolicy: React.FC = () => {
	const contactEmail = "suberoerick@gmail.com";

    return (
        <div className="flex flex-col gap-2 bg-lighter mx-14 my-6 p-3 rounded-lg shadow-md shadow-dark">
            <p className='text-center text-4xl font-bold text-darker font-montserrat'>Privacy Policy</p>
            <dl className="divide-y divide-regular">
                <div className='flex flex-col gap-2 px-5 py-4'>
                    <p className='text-xl font-bold text-darker font-montserrat'>Information Collection and Use</p>
                    <p className='text-base text-dark font-montserrat'><b>Pakata Games</b> ("we" or "us") collects information about you when you use our mobile game <b>Bubble Madness</b> (the "Game"). This information may include:</p>
                    <ul className='pl-8 list-disc'>
                        <li className='text-base text-dark font-montserrat'>Your device's unique identifier</li>
                        <li className='text-base text-dark font-montserrat'>Your game progress</li>
                        <li className='text-base text-dark font-montserrat'>Any other information you choose to provide us</li>
                    </ul>
                    <p className='text-base text-dark font-montserrat'>We use this information to operate and improve the Game, to track your progress, and to communicate with you. We may also use this information for advertising purposes.</p>
                </div>
                <InfoCard
                    title='Sharing of Information'
                    description='We may share your information with third-party service providers who help us operate the Game.'
                />
                <InfoCard
                    title="Data Security"
                    description="We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no security system is perfect, and we cannot guarantee the security of your information."
                />
                <InfoCard
                    title="Children's Privacy"
                    description={`The Game is not directed to children under the age of 13. We do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and you believe that your child has provided us with personal information, please contact us at ${contactEmail}`}
                />

                <InfoCard
                    title='Your Choices'
                    description='You can choose to opt out of certain types of advertising by visiting the Network Advertising Initiative: https://www.networkadvertising.org/ or the Digital Advertising Alliance: https://www.digitaladvertisingalliance.org/ websites.'
                />
                
                <InfoCard
                    title='Changes to this Privacy Policy'
                    description='We may change this Privacy Policy from time to time. If we make any material changes to this Privacy Policy, we will notify you by email or by a notice in the Game.'
                />

                <div className='flex flex-col gap-2 px-5 py-4'>
                    <p className='text-xl font-bold text-darker font-montserrat'>Contact Us</p>
                    <p className='text-base text-dark font-montserrat'>If you have any questions about this Privacy Policy, please contact us at</p>
                    <p className='text-base text-dark font-montserrat'>
                        <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                    </p>
                </div>
                <p className='text-right px-5 py-4 text-sm text-darker font-montserrat'>Effective Date: February 5, 2024</p>
            </dl>
        </div>
    );
}

export default BubbleMadnessPrivacyPolicy;