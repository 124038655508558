import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { RouteLocation } from 'enums/routeLocation.enum';
import Home from './pages/portfolio/Home';
import Games from 'pages/portfolio/Games';
import NoMatch from 'pages/NoMatch';
import PutASmileTermService from './pages/corporative/PutASmileTermService';
import PutASmilePrivacyPolicy from './pages/corporative/PutASmilePrivacyPolicy';
import Contact from 'pages/portfolio/Contact';
import BubbleMadnessTermService from 'pages/corporative/BubbleMadnessTermService';
import BubbleMadnessPrivacyPolicy from 'pages/corporative/BubbleMadnessPrivacyPolicy';

const App: React.FC = () => {
  const [actualRoute, setActualRoute] = useState(RouteLocation.Dashboard);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home actualRoute={actualRoute} setActualRoute={setActualRoute} />} />
        <Route path={`/${RouteLocation.Dashboard}`} element={<Home actualRoute={actualRoute} setActualRoute={setActualRoute} />} />
        <Route path={`/${RouteLocation.Games}`} element={<Games actualRoute={actualRoute} setActualRoute={setActualRoute} />} />
        <Route path={`/${RouteLocation.Contact}`} element={<Contact actualRoute={actualRoute} setActualRoute={setActualRoute} />} />
        <Route path="/put-a-smile-terms-service" element={<PutASmileTermService />} />
        <Route path="/put-a-smile-privacy-policy" element={<PutASmilePrivacyPolicy />} />
        <Route path="/bubble-madness-terms-service" element={<BubbleMadnessTermService />} />
        <Route path="/bubble-madness-privacy-policy" element={<BubbleMadnessPrivacyPolicy />} />
        <Route path="*" element={<NoMatch actualRoute={actualRoute} setActualRoute={setActualRoute} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;